import { Flex } from 'components/Flex';
import { FlexProps } from 'components/Flex/types';
import React from 'react';

export function LayoutSection({ hasPadding, ...props }: FlexProps) {
    const spacingProps: FlexProps = {
        my: { _: '5rem', lg: '6rem', full: '14rem' },
        py: hasPadding && { _: '5rem', lg: '6rem', full: '8rem' },
    };
    // allow overrides with axis props and direction props
    if (props.mt !== undefined || props.mb !== undefined) {
        spacingProps.mt = spacingProps.my;
        spacingProps.mb = spacingProps.my;
        spacingProps.my = {};
    }

    // these are still our default props so overrinding pt or pb will only set those
    // unless hasPadding was set
    if (props.pt !== undefined || props.pb !== undefined) {
        spacingProps.pt = spacingProps.py;
        spacingProps.pb = spacingProps.py;
        spacingProps.py = {};
    }

    return (
        <Flex
            backgroundColor="primaryBackground"
            {...spacingProps}
            direction="column"
            align="center"
            {...props}
        />
    );
}
