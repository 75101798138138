import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { LayoutSection } from 'components/LayoutSection';
import { SmallSubtitle } from 'components/SmallSubtitle';
import React from 'react';
import { Box } from '../Box';

// export const ClientGrid = ({ clients }) => {
//     const logoSize = 196;
//     return (
//         <Flex w="100%" justify="center">
//             <Box w="100%" maxW={clients?.length * (logoSize + 20)}>
//                 <Grid minChildWidth={logoSize} spacing="20px">
//                     {clients.map(({ name, image, svg }, index) => {
//                         return (
//                             <Flex
//                                 key={index}
//                                 // paddingX={10}
//                                 align="center"
//                                 h={[100, 125, 150]}
//                                 // m={-5}
//                                 // flexShrink={1}
//                                 // mb={{ _: 10, xl: 0 }}
//                             >
//                                 {image ? (
//                                     <div>
//                                         <img
//                                             alt={name}
//                                             width="100%"
//                                             height="auto"
//                                             src={image.publicURL}
//                                         />
//                                     </div>
//                                 ) : (
//                                     svg
//                                 )}
//                             </Flex>
//                         );
//                     })}
//                 </Grid>
//             </Box>
//         </Flex>
//     );
// };

export const ClientList = ({ clients, title, ...props }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            {...props}
        >
            <Box px="spacing-lg" mb="spacing-lg">
                <SmallSubtitle mb={0}>{title}</SmallSubtitle>
            </Box>

            <Flex
                flexGrow={1}
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
            >
                {clients.map(({ name, image, svg }, index) => {
                    return (
                        <Flex
                            key={index}
                            align="center"
                            flexBasis={{ _: 170, md: 196 }}
                            my={-4}
                            mx={0}
                        >
                            {image ? (
                                <div>
                                    <img
                                        alt={name}
                                        width="100%"
                                        height="auto"
                                        src={image.publicURL}
                                    />
                                </div>
                            ) : (
                                svg
                            )}
                        </Flex>
                    );
                })}
            </Flex>
        </Box>
    );
};

export function OurClientsAlt({ title, clients, ...props }: any) {
    return (
        <ContentWidth>
            <LayoutSection
                mt={20}
                mb="20"
                //pb={16}
                //style={{ borderBottom: '2px solid #eee' }}
                alignItems={{ _: 'center', xl: 'flex-start' }}
                backgroundColor="transparent"
            >
                <ClientList clients={clients} title={title} {...props} />
            </LayoutSection>
        </ContentWidth>
    );
}
