import { Grid } from '@audentio/stuff/src';
import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CheckListItem } from 'src/components/CheckListItem';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import {
    ClientHighlight,
    StatBox,
} from 'src/components/Methods/metrics/ClientHighlight';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { PrincipleBenefits } from 'src/components/Principle/Benefits';
import { Section } from 'src/components/Section';

export default function DiscordBots({ data }) {
    return (
        <Layout
            pageTitleFull="Build a custom Discord bot to fit your community’s needs"
            siteDescription="We create basic or complex Discord bots to meet the needs of your community members. Provide us a list of necessary features and we’ll handle the rest"
            contactForm
        >
            <MethodsLandingHero
                title="Customize your server with custom Discord bots"
                image={data.botHero.childImageSharp.fluid}
                subtitle="Whether you have an idea already or need help with strategy, we develop custom Discord bots for a variety of needs such as Discord project management bots and server insight Discord bots, with features, and integrations to fit your servers' needs."
            />

            <LandingFeatureGrid
                title="Team of professional developers and strategists to customize your Discord server"
                items={[
                    {
                        heading: 'consultation with our team',
                        content:
                            'We meet to understand your goals and server needs before we develop a feature roadmap.',
                    },
                    {
                        heading: 'align with community goals',
                        content:
                            'We identify short and long term goals to build a comprehensive feature list.',
                    },
                    {
                        heading: 'discord integrations',
                        content:
                            'Automate management with integrations or connect with a custom software or app.',
                    },
                    {
                        heading: 'upgrade existing bots',
                        content:
                            'Continue development on bots you currently own with new functionality.',
                    },
                    {
                        heading: 'maintenance and support',
                        content:
                            "We're a continual resource post-development by managing support inquiries and on-going maintenance.",
                    },
                    {
                        heading: 'on-going feature development',
                        content:
                            'On a retainer basis, we can be on-hand support, consultants, and developers for you server.',
                    },
                ]}
                mb="0"
            />

            <MediaSection
                id="stream"
                flipped
                image={data.botGantt.childImageSharp.fluid}
                title="Dedicated project manager throughout development process"
                backgroundColor="secondaryBackground"
                alt=""
            >
                <Paragraph>
                    Throughout the project, our manager will act as a point of
                    contact to answer questions, schedule tasks, and keep your
                    team up to date.
                </Paragraph>
                <Box pt="spacing">
                    {[
                        {
                            title: 'Point of contact to answer questions',
                        },
                        {
                            title: 'Schedule and monitor tasks and bug reports',
                        },
                        {
                            title:
                                'Routine meetings with your staff on progress',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                        />
                    ))}
                </Box>
            </MediaSection>

            <ClientHighlight
                title="How we developed a successful Discord bot"
                // logo={data.playactionLogo.childImageSharp.fixed}
                image={data.botPoints.childImageSharp.fluid}
                description="HouseCup.gg is a Slack and Discord bot that utilizes Harry Potter-style houses to award points to fellow team members to celebrate small victories and acknowledge good work."
                link="https://www.audent.io/case-study/housecup/full"
                linkText="View Case Study"
                pt={[0, 0, 0]}
                bg="primaryBackground"
                alt="Case study image for HouseCup.gg"
                gridSectionProps={{
                    borderTop: 0,
                }}
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox title="3.7k" subtitle="Servers" kind="created" />
                    <StatBox title="219k" subtitle="Users" kind="onboarded" />
                </Grid>
            </ClientHighlight>

            <Section
                vertical="lg"
                // pb={[null, null, 0]}
                // mb={[null, null, -150]}
                wrap
                bg="secondaryBackground"
            >
                <ClientList
                    title="Teams using our HouseCup.gg bot"
                    clients={[
                        { name: 'Columbia', image: data.columbia },
                        { name: 'Indeed', image: data.indeed },
                        { name: 'Noora Health', image: data.noora },
                        { name: 'VCU', image: data.vcu },
                        { name: 'Roundtrip', image: data.roundtrip },
                        {
                            name: 'Law Advisor Ventures',
                            image: data.lawAdvisor,
                        },
                        { name: 'Kafene', image: data.kafene },
                        { name: 'Ripple', image: data.ripple },
                    ]}
                    // pb={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>

            <PrincipleBenefits
                id="benefits"
                mt={0}
                title="How your community can benefit from a custom bot"
                ctaHref="/contact?source=discord-custom-bot"
                ctaLabel="Contact us"
                benefits={[
                    'unique features centered around a tailored community strategy',
                    'branded experience to fit your audience and brand mission',
                    'automate your workflow and build sustainable engagement',
                ]}
            />

            <MediaSection
                id="manage"
                bg="secondaryBackground"
                image={data.botAvatars.childImageSharp.fluid}
                title="Hands-on approach to managing and building your Discord community"
            >
                <Box as="p" lineHeight="1.5">
                    After building a custom Discord home for your community, we
                    continue being a guiding community leader in the growth and
                    engagement of your server.
                </Box>
                <Button
                    mt="12"
                    maxWidth="140px"
                    href="/landing/discord-community-management"
                >
                    Community management
                </Button>
            </MediaSection>

            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Related articles"
                sectionHeaderProps={{
                    direction: 'row',
                }}
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                featureGridProps={{
                    mx: 0,
                    px: 0,
                    columns: [1, 2, null, 4],
                    spacing: {
                        _: 'spacing-lg',
                    },
                }}
                showContact={false}
                items={[
                    {
                        image: data.culture.childImageSharp.fluid,
                        heading:
                            'Helping with community culture on Slack and Discord',
                        content:
                            "We've strived to be thought leaders in the community space, product designers bringing complex but useful products to the XenForo market, and UI/UX designers for what a forum interface...",
                        href:
                            'https://adjourn.audent.io/helping-with-community-culture-on-slack-and-discord-5fa6941f69e9/',
                    },
                    {
                        image: data.guide.childImageSharp.fluid,
                        heading: 'How to build a community on Discord',
                        content:
                            "We're continuing our community guide series to take another look at building a community in Discord. With all of the new features they've added this year and new takes on community strategy, we'll look at how to grow monetization, engagement, and more in your server.",
                        href:
                            'https://adjourn.audent.io/how-to-build-a-community-on-discord/',
                    },
                    {
                        image: data.growing.childImageSharp.fluid,
                        heading:
                            'Growing a community with gamification and incentivization',
                        content:
                            'Online communities, specifically forums, are struggling to keep up with the modern user experience that large social media platforms...',
                        href:
                            'https://adjourn.audent.io/building-an-active-community-through-incentivization-and-gamification-eb0551232b22/',
                    },
                    {
                        image: data.toxicity.childImageSharp.fluid,
                        heading: 'Moderation & Toxicity Prevention Techniques',
                        content:
                            'To maintain a healthy and thriving community, we share the tools to implement from the beginning to help moderate content and prevent toxicity from spreading...',
                        href:
                            'https://adjourn.audent.io/moderation-toxicity-prevention-techniques-3fe9baba3e65/',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query DiscordBotQuery {
        botHero: file(relativePath: { eq: "images/discord/bot-hero.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        botAvatars: file(
            relativePath: { eq: "images/discord/bot-avatars.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        botGantt: file(relativePath: { eq: "images/discord/bot-gantt.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        botPoints: file(relativePath: { eq: "images/discord/bot-points.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        culture: file(relativePath: { eq: "images/discord/culture.png" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        growing: file(relativePath: { eq: "images/discord/growing.png" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        guide: file(relativePath: { eq: "images/discord/guide.jpg" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        toxicity: file(relativePath: { eq: "images/discord/toxicity.jpg" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        columbia: file(relativePath: { eq: "images/clients/columbia.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        indeed: file(relativePath: { eq: "images/clients/indeed.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        noora: file(relativePath: { eq: "images/clients/noora.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        vcu: file(relativePath: { eq: "images/clients/vcu-logo.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        roundtrip: file(relativePath: { eq: "images/clients/roundtrip.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        lawAdvisor: file(
            relativePath: { eq: "images/clients/law-advisor.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        kafene: file(relativePath: { eq: "images/clients/kafene.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        ripple: file(relativePath: { eq: "images/clients/ripple.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
