import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { ContentWidth } from 'components/ContentWidth';
import {
    GridSection,
    GridSectionLeft,
    GridSectionRight,
} from 'components/GridSection';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { SmallSubtitle } from 'components/SmallSubtitle';
import { ThemeProvider } from 'components/ThemeProvider';
import React from 'react';

export function PrincipleBenefits({
    title,
    ctaLabel,
    ctaHref,
    benefits,
    ...rest
}: any) {
    return (
        <ThemeProvider defaultMode="dark">
            <LayoutSection
                mb={0}
                hasPadding
                backgroundColor="primaryBackground"
                {...rest}
            >
                <ContentWidth>
                    <GridSection>
                        <GridSectionLeft
                            px={{ _: 0, lg: 32 }}
                            maxWidth={{ _: '100%', lg: 400 }}
                            flexDirection="column"
                        >
                            <Heading fontSize="sectionHeading" as="h3">
                                {title}
                            </Heading>

                            {ctaHref && ctaLabel && (
                                <Box mt={5}>
                                    <Button href={ctaHref} fill="white">
                                        {ctaLabel}
                                    </Button>
                                </Box>
                            )}
                        </GridSectionLeft>

                        <GridSectionRight
                            mt={{ _: 20, lg: 0 }}
                            flexDirection="column"
                        >
                            <SmallSubtitle mt={5} mb={12} color="bodyText">
                                Goals
                            </SmallSubtitle>

                            <Box as="ol">
                                {benefits.map((benefit, key) => (
                                    <Box
                                        as="li"
                                        color="boldText"
                                        fontSize="paragraphTitle"
                                        key={key}
                                        py={6}
                                        lineHeight="tall"
                                        borderBottom={
                                            key === 2 ? '0' : '1px solid'
                                        }
                                        borderBottomColor="border"
                                    >
                                        {benefit}
                                    </Box>
                                ))}
                            </Box>
                        </GridSectionRight>
                    </GridSection>
                </ContentWidth>
            </LayoutSection>
        </ThemeProvider>
    );
}
